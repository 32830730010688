@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.stage_container {
	display: flex;
	align-items: center;
	position: relative;
	flex-direction: column;
	width: 112px;

	@include media($phone_all...) {
		width: 80px;
		top: 2rem;
	}

	&:hover {
		cursor: pointer;
	}

	.percent {
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;
		margin-top: -16px;
		margin-bottom: 10px;

		@include media($phone_all...) {
			margin-bottom: 2px;
			font-size: 12px;
		}
	}

	svg {
		font-size: 16px;
		margin-top: -14px;
		margin-bottom: 10px;
		color: #3dcf18;
	}

	.stage {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: transparent;
		height: 112px;
		width: 112px;
		border-radius: 50%;

		@include media($phone_all...) {
			height: 78px;
			width: 78px;
		}

		.ring_image {
			position: absolute;
			width: 100%;
			border-radius: 50%;
		}

		.center_image {
			position: absolute;
			width: 45%;
		}
	}

	.text {
		width: 190px;
		display: block;
		color: #8c8c8c;
		font-size: 14px;
		line-height: 1rem;
		font-weight: 300;
		text-align: center;
		position: absolute;
		bottom: -34px;

		@include media($phone_all...) {
			height: 0;
			width: 80px;
			font-size: 12px;
			display: flex;
			bottom: -16px;
		}
	}

	.metrics {
		position: absolute;
		bottom: -72px;
		width: 190px;
		display: flex;
		justify-content: center;

		@include media($phone_all...) {
			width: 90px;
			bottom: -145px;
			justify-content: center;
			flex-wrap: wrap;
			align-items: flex-start;
			height: 100%;
			display: none;
		}

		svg {
			margin-right: 8px;
			font-size: 16px;
			color: #fff;
			opacity: 35%;

			&.completed {
				color: #01b6df;
				opacity: 1;
			}
		}
	}
}
