.add_team_form {
	padding: 1rem;
	margin: 1rem 0 2rem;
	position: relative;

	.actions {
		margin-top: 1rem;
		text-align: right;
	}
}

.team_user {
	display: flex;
	padding: 10px;

	div {
		display: flex;
		flex-direction: column;

		h2 {
			font-size: 18px;
		}

		p {
			font-size: 14px;
		}
	}
}
