.activity_item {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 5px;

		svg {
			font-size: 20px;
			color: #08f996;
			margin-right: 18px;
		}

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 27px;
			color: #e9e9e9;

			span {
				font-weight: 700;
				color: #fff;
			}
		}

		.date {
			margin-left: auto;
			font-size: 14px;
			line-height: 24px;
		}
	}

	& > p {
		img {
			position: relative;
			top: 0.4rem;
			width: 20px;
			height: 20px;
			margin: 0 2px;
		}
	}

	& > p,
	.rating {
		margin-left: 38px;
		margin-bottom: 5px;
		font-size: 13px;
	}

	.description {
		span {
			font-weight: 600;
		}
	}
}
