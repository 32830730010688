@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.carousel {
	width: 100%;
	position: relative;
	margin-top: 10px;
	margin-bottom: 30px;

	@include media($phone_all...) {
		max-width: 100% !important;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.loggedOutBanner {
		display: block;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 10px;
		margin: 0 30px 0 10px;
		background: linear-gradient(90deg, #f768ca 0%, #4e4bde 50%, #01b6df 100%);
		border-radius: 8px;

		@include media($phone_all...) {
			margin-right: 10px;
		}

		p {
			display: block;
			font-family: var(--font-inter);
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 28px;
			color: #fff;
			@include media($phone_all...) {
				line-height: 24px;
			}
		}
		.white_button {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
			padding: 8px 16px !important;
			color: #000;
			font-family: Inter !important;
			font-size: 14px !important;
			font-style: normal !important;
			font-weight: 500 !important;
			line-height: 20px !important;
			text-decoration: none;
			margin: 5px 0 0;
			height: 36px;
		}
	}

	.list_container {
		.banner_item {
			width: 100% !important;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				cursor: pointer;
				border-radius: 8px;
				width: calc(100% - 100px);
				height: auto;
				@include media($phone_all...) {
					width: 95%;
					border-radius: 5px;
				}
			}
		}
	}

	.prev {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;

		&:disabled {
			opacity: 0.25;
		}

		&:hover {
			background: none;
		}

		@include media($phone_all...) {
			display: none;
		}
	}

	.next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		border-radius: 0;

		&:disabled {
			opacity: 0.25;
		}

		@include media($phone_all...) {
			display: none;
		}
	}

	.indicators {
		display: flex;
		justify-content: center;
		height: 0;

		.indicator {
			background-color: #fff;
			width: 8px;
			height: 8px;
			margin-right: 5px;
			border-radius: 50%;
			margin-top: 10px;

			&.selected {
				opacity: 50%;
			}
		}
	}
}
