.verified_preview {
	border-radius: 20px !important;
	background: #000 !important;
	height: 97px;
	width: 329px;
	position: relative;
	display: table;
	padding: 0 1rem 1rem;
	box-shadow: 0rem 0rem 0.75rem #000;

	h2 {
		color: #fff;
		font-size: 1rem;
		max-width: 100%;
		text-align: left;
	}

	svg {
		font-size: 14px;
		color: #1aeff3;
		float: left;
		margin-right: 0.5rem;
		margin-top: 0.1rem;
	}

	p {
		float: left;
		color: #fff;
		margin: 0;
		margin-bottom: 0.75rem;
		padding: 0;
		font-size: 0.8rem;
		font-weight: 300;
		line-height: 1.1rem;
		text-align: left;
		width: 92%;

		a {
			color: #01b6df;
			text-decoration: none;
		}
	}
}
