.activities {
	width: 90%;

	.footer {
		display: flex;
		align-items: center;
		justify-content: center;

		button {
			background: rgba(0, 0, 0, 0.5);
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 20px;
			padding: 4px 26px;
			width: 122px;
		}
	}
}
