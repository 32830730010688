@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.member_list {
	margin-bottom: 1rem;
	background: linear-gradient(to right, #d900c0, #016eda);
	box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25) !important;
	border-radius: 15px !important;
	color: #fff !important;

	@include media($phone_all...) {
		margin-bottom: -0.5rem;
	}

	> div {
		border: 1px solid transparent;
	}

	.teamCard {
		padding: 16px;
		background: radial-gradient(
			123.22% 129.67% at 100.89% -5.6%,
			#201d47 0%,
			#17153a 100%
		);
		border-radius: 15px;
	}

	.header {
		display: flex;
		justify-content: space-between;

		.close {
			color: #fff;
			align-self: flex-start;
			font-size: 0.8rem;
		}
	}

	.about {
		p {
			display: block;
			font-size: 1rem;
			line-height: 1.5rem;
			margin: 0.75rem 0;
			color: #bcb2aa;
		}
	}

	.read_more {
		color: #fff;
	}
}
