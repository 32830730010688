@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.filtered_data_chip {
	width: 100%;
	margin-top: 0;
	margin-left: 0.75rem;

	@include media($phone_all...) {
		margin-top: 1rem;
		margin-bottom: 1rem;
		margin-left: 4rem;
	}

	ul {
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 0;

		div {
			position: relative;
			align-items: center;
			cursor: pointer;
			display: flex;
			padding: 0.3rem 0;
			border: 1.5px solid #5ad2f2;
			border-style: solid;
			background-color: rgba(21, 178, 229, 0.06);
			border-radius: 0.5rem;
			margin-right: 0.75rem;
			transition: all 0.2s linear;
			height: auto;
			font-size: 0.9rem;

			span {
				color: #fff;
			}

			&:hover {
				border-color: #8a939b;
			}

			svg {
				color: #fff !important;

				&:hover {
					color: #fff;
				}
			}
		}
	}
}
