.social_button {
	background: linear-gradient(to right, #d900c0, #016eda);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 46px;

	& > svg {
		display: none;
	}

	&.disabled {
		background: linear-gradient(to right, #540046, #04284f);

		& > div {
			border: 1px solid transparent;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			img {
				position: absolute;
			}
		}

		& > svg {
			display: block;
			position: absolute;
		}

		button {
			background-color: #07111e;
			color: #595959;
			border: 2px solid transparent;

			svg {
				color: #113d47;
			}
		}
	}

	&.dark {
		button {
			background: #000;
		}
	}

	& > div {
		border: 1px solid transparent;
	}

	button {
		background: #152d53;
		border-radius: 4px;
		height: 44px;
		min-width: 70px;
		text-transform: none;

		p {
			display: block;
		}

		svg {
			color: #53abc9;
		}

		&:hover {
			background: #152d53;
		}
	}
}
