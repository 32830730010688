@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.userList {
	margin-top: 20px;

	.load_more {
		width: 100%;
	}
}
