@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.stages {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding-top: 30px;
	margin-bottom: 100px;

	@include media($phone_all...) {
		flex-wrap: wrap;
		padding-top: 10px;
		margin-bottom: 20px;
	}

	.goscore {
		display: flex;
		align-items: flex-start;
		height: 100%;

		@include media($phone_all...) {
			height: 104px;
			margin-bottom: 40px;
		}
	}

	.right_section {
		display: flex;
		width: 100%;
		height: 100%;

		@include media($phone_all...) {
			height: auto;
		}
	}

	.stage_container {
		display: flex;
		height: 100%;
		width: 100%;
	}
}

.bar {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex: auto;

	@include media($phone_all...) {
		display: none;
	}

	.back {
		position: absolute;
		background-color: #000;
		height: 5px;
		border-radius: 5px;
		width: calc(100% - 20px);
		margin-bottom: -15px;

		.progress {
			height: 5px;
			border-radius: 5px;
			background-color: #01b6df;
		}
	}
}
