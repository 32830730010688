@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.content {
	padding: 0 0 16px 0;
	display: flex;
	flex-direction: column;
	letter-spacing: 0px;

	.header {
		position: relative;
		max-height: 170px;
		overflow: hidden;
		border-radius: 14px 14px 0 0;
		letter-spacing: 0px;

		.poster {
		}
	}

	.desc {
		margin: 5px auto 20px auto;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0px;
	}

	.bar {
		height: 4px;
		border-radius: 5px;
		flex-grow: 1;
		margin: 10px;
	}

	.info {
		display: flex;
		flex-direction: column;

		.top {
			padding: 10px 20px;
			background: #161622;
			display: flex;
			justify-content: space-between;
			align-items: center;
			letter-spacing: 0px;

			.owner {
				font-size: 10px;
				letter-spacing: 0px;
			}

			.title {
				font-weight: 600;
				background: linear-gradient(45deg, #6b3bfa, #f11e76);
				background: -webkit-linear-gradient(45deg, #6b3bfa, #f11e76);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				letter-spacing: 0px;
				line-height: 17px;
			}

			.status_pill {
				font-size: 11px;
				background-color: #3cbb5b;
				border-radius: 50px;
				padding: 0px 20px;
				font-weight: 600;

				&.ended {
					color: #000;
					background: linear-gradient(180deg, #ffd873 0%, #f2c44d 100%);
				}
			}
		}

		.breakdown {
			padding: 16px;
			display: flex;
			flex-direction: column;
			margin-bottom: 50px;

			h3 {
				font-size: 12px;
				font-weight: 600;
				letter-spacing: 0px;
			}
		}

		.results {
			display: flex;

			& > div {
				flex-basis: 50%;

				.votingWeight_container {
					display: flex;
					align-items: center;

					.bar {
						height: 4px;
						border-radius: 5px;
						flex-grow: 1;
						margin: 10px;
					}

					.text {
						color: #fff;
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: 13px;
						letter-spacing: 0px;
					}

					button {
						padding-left: 0px;

						svg {
							font-size: 9px;
							color: #08f996;
						}
					}
				}

				.voteTotal {
					display: flex;
					align-items: center;
					margin-top: -8px;

					button {
						padding-left: 0px;

						svg {
							font-size: 9px;
							color: #fff;
						}
					}

					p {
						font-size: 12px;
						font-weight: 600;
						letter-spacing: 0px;
					}
				}

				&.left {
					padding-right: 20px;
				}

				&.right {
					font-size: 10px;
					padding-left: 20px;

					& > div {
						display: flex;
						justify-content: space-between;
						align-items: center;

						p {
							font-size: 10px;
							letter-spacing: 0px;
						}
					}
				}
			}
		}

		.summary {
			margin-top: 15px;

			.desc {
				max-height: 175px;
				overflow-y: auto;
				background: rgba(0, 0, 0, 0.3);
				padding: 10px 15px 10px;

				p {
					margin: 0;
					margin-bottom: 15px;
					line-height: 15px;

					strong {
					}
				}
			}
		}

		.vote_breakdown {
			h3 {
				margin-bottom: 5px;
			}
			& > div {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.text {
					color: #fff;
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: 13px;
					min-width: 25px;
					letter-spacing: 0px;
				}

				.text_fan,
				.text_percent {
					color: #fff;
					font-size: 12px;
					font-style: normal;
					font-weight: 600;
					line-height: 13px;
					min-width: 55px;
					margin: auto 10px;
					letter-spacing: 0px;
				}

				.bar {
					height: 4px;
					border-radius: 5px;
					flex-grow: 1;
					margin: 10px 20px;
					max-width: 285px;
				}
			}
		}
	}

	.footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		padding: 15px 30px;
		width: 60%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		background: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(30px);
		border-radius: 5px 5px 0px 0px;

		@include media($phone_all...) {
			position: fixed;
			bottom: 35px;
		}

		.approveBtn {
			border: 0.1rem double transparent;
			background: #fff;
			padding: 2px 30px;
			max-width: 92px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0px;
			color: #000;

			&:disabled {
				opacity: 0.25;
			}
		}

		.rejectBtn {
			background: #fff;
			color: #000;
			padding: 2px 30px;
			text-transform: uppercase;
			max-width: 92px;
			font-weight: 600;
			letter-spacing: 0px;

			&:disabled {
				opacity: 0.25;
			}
		}
	}
}
