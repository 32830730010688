.trailer_dialog {
	position: relative;

	.thumbnail {
		max-height: 130px;
		border: 1px solid #ffffff;
		border-radius: 11px;
		margin-right: 25px;
		cursor: pointer;
	}

	button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50px, -14px);

		img {
			width: 60px;
			height: 60px;
		}
	}
}
