.review_item {
	.reviews {
		margin: 0px 0 16px 0;
		margin-top: 1rem;
		position: relative;
		z-index: 1;

		p {
			font-size: 1rem;
			line-height: 1.2rem;
			font-weight: normal;
			color: #fff;
			margin: 0;
		}
		
		p.review {
			margin-top: 1.25rem;
		}

		h5 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0.75rem;

			a {
				font-size: 14px;
				font-weight: 400;
			}
		}

		.review {
			background: rgba(0, 0, 0, 0.35);
			margin: 16px 0 16px 0;
			border-radius: 10px;
			position: relative;
			
			&.review_first_fhild {
				padding-top: 1.5rem;
				
				.heading {
					padding-top: 0;
				}
			}
			
			&.review_first_fhild:after {
				content: ' ';
				position: absolute;
				inset: 0;
				border-radius: 10px;
				border: 2px solid transparent;
				background: linear-gradient(
					101.21deg,
					#01bf52 13.13%,
					#01acbd 90.45%
				) !important;
				-webkit-mask: linear-gradient(#fff 0 0) padding-box,
					linear-gradient(#fff 0 0);
				mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
			}
			
			.userReview {
				h5 {
					font-weight: bold;
					font-size: 20px;
					margin-top: 0;
					margin-bottom: 1.25rem;
					margin-left: 1.5rem;
				}
			}
			
			.heading {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				padding: 1.5rem 1.5rem 0 1.5rem;

				.hash {
					font-size: 0.8rem;
					line-height: 1.2rem;
					
					a {
						color: #2887BD;
					}
				}

				span {
					font-size: 1rem;
					align-self: anchor-center;
					align-items: center;
					justify-self: end;
				}

				button {
					color: #fff;

					svg {
						font-size: 0.8rem;
					}
				}
			}

			.content {
				position: relative;
				z-index: 999;
				margin: 0px 0;
				padding: 0 1.5rem 0;
				line-height: 1.3rem;
				font-size: 0.9rem;
				font-weight: normal;
				font-family: 'Titillium Web', sans-serif;
				color: #fff;
			}
			
			p {
				background: none;
			}

			.footer {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 16px 0;

				span {
					display: flex;
					align-items: center;
				}

				.add_comment_btn {
					background: #3cbb5b;
					width: 35px;
					height: 35px;
					color: #fff;
					border-radius: 50%;
					cursor: pointer;
					border: none;
					margin-left: auto;

					svg {
						width: 1rem;
					}

					&:hover {
						background: #083ea3;
					}
				}

				.reactions {
					button {
						background: #000;
						color: #fff;
						height: 40px;
						padding: 0 10px;
						min-width: auto;
						margin-left: 10px;

						&:hover {
							background: #083ea3;
						}

						img {
							height: 20px;
							margin-right: 4px;
						}

						div {
							font-size: 1rem;
						}
					}
				}
			}

			.reviewer {
				display: inline-flex;
				align-items: center;
				width: 100%;

				.name {
					font-size: 0.9rem;
					font-weight: normal;
					line-height: 2rem;
				}
			}

			.total_staked {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;

				img {
					width: 1.5rem;
					margin-right: 0.75rem;
				}

				span {
					font-size: 0.9rem;
				}
			}
		}
	}
}
