@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.custom_countdown {
	display: flex;
	justify-content: space-between;
	border-radius: 10px;
	padding: 14px;
	margin-top: 30px;

	@include media($phone_all...) {
	}

	.ended {
		opacity: 0.5;

		@include media($phone_all...) {
		}

		span {
			background: rgba(255, 255, 255, 0.2);
			border-radius: 6px;
			color: #fff;
			padding: 3px 6px;
			font-size: 10px;
			letter-spacing: initial;
			margin: 0px;
		}
	}

	.message {
		font-size: 15px;
		font-weight: 600;
		margin-top: 3px;
	}

	&.black {
		background: #161622;
	}

	&.blue {
		margin: 0 auto;
		padding: 8px 10px;
		display: flex;
		justify-content: center;
		background: transparent;
		width: 80%;

		@include media($phone_all...) {
			max-width: 100%;
			margin-bottom: 10px;
		}

		.message {
			display: none;
		}
	}

	&.darkBlue {
		margin-top: 0;
		padding: 18px 20px 18px 25px;
		display: flex;
		background: #161622;
		width: 100%;
		position: relative;

		@include media($phone_all...) {
			position: relative;
			max-width: initial;
			top: 10px;
		}

		.message {
			font-size: 23px;
			margin-top: 2px;
			font-weight: 600;
			letter-spacing: 0;
			display: inline-block;
			background: linear-gradient(45deg, #6b3bfa, #f11e76);
			background: -webkit-linear-gradient(45deg, #6b3bfa, #f11e76);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			@include media($phone_all...) {
				font-size: 13px;
			}
		}
	}

	&.dao_proporsal {
		margin-top: -5px;
		padding: 0;

		@include media($phone_all...) {
			text-align: center;
		}

		.points {
			display: none;
		}
	}
}

.countdown_number {
	&.black {
		background: rgba(255, 255, 255, 0.2);
		border-radius: 6px;
		color: #fff;
		padding: 5px 8px;
		font-size: 14px;
		margin: 0 2px;
	}

	&.blue {
		background: rgba(255, 255, 255, 0.2);
		border-radius: 6px;
		color: #fff;
		padding: 5px 20px;
		font-size: 18px;
		margin: 0 2px;
		line-height: 12px;
	}

	&.darkBlue {
		background: rgba(255, 255, 255, 0.2);
		border-radius: 6px;
		color: #fff;
		padding: 3px 10px;
		font-size: 15px;
		margin: 0 4px;
		position: relative;
		top: -2px;
	}

	&.dao_proporsal {
		background: rgba(255, 255, 255, 0.2);
		border-radius: 6px;
		color: #fff;
		padding: 3px 6px;
		font-size: 10px;
		margin: 0 2px;
	}
}
