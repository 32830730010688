@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.top_genre_project {
	display: block;
	margin-bottom: 40px;

	.top {
		display: flex;
		align-items: center;
		margin-bottom: 0px;

		p,
		a {
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #fff;

			@include media($phone_all...) {
				line-height: 18px;
				font-size: 16px;
				margin-right: 0;
			}
		}

		a {
			max-width: 45%;

			@include media($phone_all...) {
				max-width: 34%;
			}
		}

		.goscore {
			width: 32px;
			height: 32px;
			margin-right: 12px;

			@include media($phone_all...) {
				margin-right: 8px;
			}

			div {
				max-width: 32px;
				max-height: 32px;
			}
		}

		.stats {
			display: flex;
			margin-left: auto;

			li {
				margin-right: 5px;
				font-size: 15px;

				@include media($phone_all...) {
					margin-right: 8px;
					font-size: 14px;
				}

				svg {
					color: #08f996;
					font-size: 15px;
					margin-right: 6px;

					@include media($phone_all...) {
						font-size: 14px;
					}
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer {
		margin-top: 0.75rem;

		.categories {
			display: flex;
			justify-content: space-between;

			.development,
			.originality,
			.adoption,
			.distribution {
				background: #f90b43;
				height: 35px;
				vertical-align: middle;
				justify-content: center;
				padding: 6px;
				font-style: normal;
				font-weight: 600;
				font-size: 11px;
				line-height: 24px;
				padding: 8px 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 20px;
				text-transform: uppercase;
				border-radius: 3px;
				color: #fff;
				margin-right: 20px;
				pointer-events: none;

				@include media($phone_all...) {
					margin-right: 10px;
				}
			}

			.originality {
				background: #08f996;
				color: #000 !important;
			}

			.adoption {
				background: #fc8e0b;
			}

			.distribution {
				background: #f50bfc;
			}

			@include media($phone_all...) {
			}

			@include media($phone_all...) {
				flex-wrap: wrap;
				justify-content: right;
			}

			li {
				@include media($phone_all...) {
					margin-bottom: 12px;
				}

				&:first-child {
					height: 35px;
					vertical-align: middle;
					justify-content: center;
					padding: 6px;
					font-style: normal;
					font-weight: 600;
					font-size: 11px;
					line-height: 24px;
					padding: 8px 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					line-height: 20px;
					text-transform: uppercase;
					border-radius: 3px;
					color: #fff;
					margin-right: 20px;

					@include media($phone_all...) {
						margin-right: 10px;
					}
				}

				&.disabled {
					a,
					svg {
						opacity: 50%;
					}
				}

				a {
					width: auto;
					height: 35px;
					background: #00000080;
					border-radius: 3px;
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					line-height: 24px;
					padding: 10px 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;

					svg {
						font-size: 18px;
						color: #08f996;
						margin-right: 3px;
					}

					@include media($phone_all...) {
						min-width: 111px;
					}
				}
			}
		}
	}
}
