@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.container {
	display: flex;
	width: 420px;
	padding: 50px 0;
	border-radius: 8px;
	background: #fff;
	row-gap: 30px;
	flex-direction: column;
	align-items: center;
}

.circular_progress {
	position: relative;
	height: 250px;
	width: 250px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	&:after {
		content: '';
		position: absolute;
		width: 100% !important;
		height: 100%;
		border-radius: 50%;
		box-shadow: inset 0px 0px 11px 1px #5bcdec;
	}
}

.circular_progress_before {
	position: absolute;
	height: 210px;
	width: 210px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.progress_value {
	position: relative;
	font-size: 40px;
	font-weight: 600;
	color: #7d2ae8;
}

.text {
	font-size: 30px;
	font-weight: 500;
	color: #606060;
}
