#detail-social-share-menu {
	.MuiMenu-paper {
		border-radius: 11px;
		margin-top: 54px;
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		border: 1px solid #2e2bba;
		color: #fff;

		ul {
			padding: 10px 20px 10px 5px;
		}
	}

	.MuiTypography-root {
		font-size: 14px;
	}

	a {
		color: #fff;
	}

	.backDrop {
		position: relative;
		border: 2px solid #fff;
		border-radius: 50%;
		width: 36px;
		height: 36px;
		background: linear-gradient(
			137.48deg,
			rgba(255, 83, 188, 0.5) 15.13%,
			rgba(10, 252, 212, 0.5) 87.01%
		);
		backdrop-filter: blur(20.9709px);
		vertical-align: middle;
		margin-right: 15px;

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 15px !important;
			height: 15px !important;
		}
	}
}
