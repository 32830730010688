@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.subtitle {
	font-weight: 700;
	font-size: 16px;
	margin-top: 32px;
	margin-bottom: 12px;
	color: #ffffff;
}

.header_title {
	margin-top: 25px !important;
	position: relative;

	p {
		font-weight: 400;
		font-size: 17px;
		line-height: 24px;
		color: #ffffff;
	}
}

.header {
	position: relative;
	display: flex;
	padding-bottom: 2rem;

	.status {
		border-radius: 14px;
		background-color: #000;
		padding: 26px 30px;
		width: 100%;

		@include media($phone_all...) {
			padding: 15px;
		}

		.grow_hacks {
			padding-left: 0;
			margin-top: 0.5rem;

			> div {
				background: none;
				height: initial !important;
			}

			button {
				position: relative;
				padding: 5px 15px;
				margin-top: 5px;
				height: initial !important;
				border: 1px solid #016eda;
				border-radius: 5px;

				svg {
					font-size: 15px;
				}
			}
		}

		.text {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: normal;
		}

		.title {
			@extend .text;
			color: #ffffff;
			margin-bottom: 4px;
		}

		.copy_link {
			font-size: 10px;
			background: #152d53;
			border-radius: 25px;
			padding: 4px 14px;
			margin: 10px 0 15px 24px;

			&:disabled {
				color: #d8d4d447;
			}
		}

		.link_copied {
			font-size: 10px;
			font-weight: 600;
			background-color: #3ebb5b;
			color: #fff;
			border-radius: 25px;
			padding: 4px 14px;
			margin: 10px 0 15px 24px;
			width: 70%;

			svg {
				font-size: 12px;
				margin-left: 5px;
			}
		}

		.info {
			display: flex;
			margin: 0.5rem 0 0;

			p {
				@extend .text;
				font-size: 11px;
				line-height: 13px;
			}

			svg {
				color: #53abc9;
				border-radius: 50%;
				padding: 3px;
				margin-right: 3px;
				font-size: 16px;
			}
		}
	}

	.grow {
		border-radius: 14px;
		background-color: #000;
		padding: 20px;
		width: 100%;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		align-items: center;

		@include media($phone_all...) {
			justify-content: space-evenly;
			padding: 26px 5px;
		}

		& > div {
			margin: 9px 0;

			button {
				width: 155px;
				padding: 0;
			}
		}
	}
}

.top_genres {
	max-width: 100%;

	@include media($phone_all...) {
		margin-top: 80px !important;
	}

	.header {
		display: block;

		h3 {
			font-weight: 700;
			display: block;
			margin-bottom: 6px;
		}
	}

	.categories {
		margin: -10px 0 40px;
		padding: 0;
		list-style: none;
		display: flex;

		li {
			margin-right: 10px;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			line-height: 24px;
			padding: 4px 18px;
			border-radius: 20px;
			background: #00000080;
			width: auto;
			pointer-events: none;

			&:last-child {
				background-color: transparent;
				padding-left: 0;
				pointer-events: initial;
			}
		}
	}
}

.projectinfo {
	float: left;
	display: block;
	width: 100%;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media($phone_all...) {
			flex-wrap: wrap;
		}

		h5 {
			font-weight: 700;
			margin-bottom: 1rem;
			font-size: 32px;
			line-height: 24px;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 10px;
				font-style: normal;
				font-weight: 300;
				font-size: 14px;
				line-height: 24px;
				padding: 4px 18px;
				border-radius: 20px;
				background: #00000080;

				&:first-child {
					font-weight: 600;
					text-transform: uppercase;
					color: #000;
				}
			}
		}
	}

	.media {
		.media_header {
			display: flex;
			align-items: baseline;

			a {
				color: #59b7d8;
				margin-left: 20px;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
			}
		}

		h3 {
			@extend .subtitle;
		}

		.images {
			display: flex;

			& > img {
				max-height: 130px;
				border: 1px solid #ffffff;
				border-radius: 11px;
				margin-right: 25px;
			}
		}
	}

	.documents {
		h3 {
			@extend .subtitle;
		}

		& > div {
			display: flex;
			justify-content: flex-start;

			a {
				font-weight: 300;
				font-size: 14px;
				line-height: 24px;
				color: #ffffff;
				background: rgba(0, 0, 0, 0.5);
				border-radius: 10px;
				height: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 8px;
				padding: 12px 18px;

				svg {
					color: #08f996;
					margin-right: 8px;
				}
			}
		}
	}

	.show_about {
		width: 100%;
		margin-top: 2rem;
		text-align: center;
		display: flex;
		justify-content: center;
		position: relative;

		button {
			background: rgba(0, 0, 0, 0.5);
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 20px;
			padding: 4px 26px;
		}

		&.show_more::before {
			content: '';
			width: 100%;
			position: absolute;
			height: 5rem;
			bottom: 65px;
			background: linear-gradient(180deg, rgba(33, 32, 46, 0) 0, #121212 90%);
			z-index: 1;
		}
	}

	.about {
		display: block;
		color: #bcb2aa;
		line-height: 1.3rem !important;
		font-size: 0.9rem;
		margin: 0.5rem 0;

		&.showMore {
			max-height: 25.5rem;
			min-height: 25rem;
			overflow: hidden;
		}

		img {
			max-width: 100%;
		}

		h3 {
			@extend .subtitle;
		}

		p {
			display: block;
			color: #bcb2aa;
			line-height: 1.3rem;
			font-size: 0.9rem;
			margin: 0.5rem 0;
			letter-spacing: 0.1rem;
		}
	}
}

.badges_achievements {
	background: rgba(0, 0, 0, 0.35);
	border-radius: 20px;
	padding: 30px;
	max-width: 100%;

	@include media($phone_all...) {
		padding: 10px;
	}

	h3 {
		@extend .subtitle;

		&:first-child {
			margin-top: 5px;
		}
	}
}
