@import 'Variable.scss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.project_item {
	width: 280px;
	height: auto;
	display: inline-block;
	position: relative;
	margin: 15px 10px 15px;
	background: none;
	z-index: 10;

	@include media($phone_all...) {
		margin: 0;
		width: 100%;
		max-width: 280px;
		height: 100%;
	}

	.posterWrapper {
		position: relative;
		border-radius: 11px;
		display: inline-block;
		border: 2px solid #206193;
		overflow: hidden;
		width: 276px;
		height: 404px;

		@include media($phone_all...) {
			max-width: 100%;
			width: 100%;
			max-height: calc(17rem - 2vw);
			height: 100%;
		}
	}

	.inPerks {
		border: 2px solid #6B3BFA;

		.SocialContainer {
			padding: 9px 12px 11px 12px;
			border-top: 2px solid #6B3BFA;
		}
	}

	img.artwork {
		width: 100%;
		height: 100%;
		object-fit: cover;
		float: left;
	}

	.SocialContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 9px 12px;
		position: absolute;
		bottom: 0;
		width: 100%;
		background: rgba(6, 33, 55, 0.7);
		backdrop-filter: blur(2px);
		border-top: 2px solid #206193;

		@include media($phone_all...) {
			padding: 9px 9px;
			justify-content: space-between;
		}

		.staff_pick {
			border-radius: 50%;
			width: 11%;
			height: 11%;

			@include media($phone_all...) {
				width: 30px;
				height: 30px;
				background: rgba(0, 0, 0, 0.4);
				backdrop-filter: blur(20px);
				-webkit-backdrop-filter: blur(20px);
				position: absolute;
				z-index: 10;
				top: -35px;
				right: 5px;
			}

			img {
				width: 100%;
				position: initial;
			}
		}

		.likes {
			display: flex;
			width: auto;
			align-items: center;
			justify-content: center;
			position: relative;

			button {
				padding: 0.1rem;
			}

			span {
				color: #fff;
				font-size: 0.9rem;
				font-weight: 600;
			}

			svg {
				font-size: 16px;
				color: #08f996;
				margin-right: 0.2rem;

				@include media($phone_all...) {
					margin-right: 0;
				}
			}
		}

		.greenlight_btn {
			width: 58%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			@include media($phone_all...) {
				width: 65%;
			}

			a {
				background: linear-gradient(270deg, #00bf51 13.71%, #00acbd 78.86%);
				border-radius: 25px;
				font-style: normal;
				font-weight: 600;
				font-size: 0.81rem;
				line-height: 24px;
				text-align: center;
				color: #fff;
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				@include media($phone_all...) {
					font-size: 0.5rem;
				}

				div {
					background: rgba(0, 0, 0, 0.75);
					width: calc(100% - 2px);
					height: calc(100% - 2px);
					border-radius: 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					text-wrap: nowrap;
				}

				img {
					height: 18px;
					width: 18px;
					margin-right: 5px;
				}

				&:hover {
					div {
						background: transparent;
					}
				}
			}
		}
	}

	.item_box {
		display: flex;
		justify-content: space-evenly;
		margin: -3px 10px 0;
		padding: 5px 0 10px 0;
		position: relative;
		float: left;
		width: 90%;
		background: rgba(17, 0, 0, 1);
		backdrop-filter: blur(2px);
		border-radius: 0px 0px 9px 9px;

		@include media($phone_all...) {
			display: none;
		}

		.stage_button {
			text-align: left;
			font-weight: 600;
			margin: 0;
			min-width: 7rem;

			span {
				text-transform: uppercase;
				background: #000;
				font-size: 0.5rem;
				color: $whiteColor;
				padding: 7px 8px;
				border-radius: 3px;
			}
		}

		.likes {
			display: flex;
			align-items: center;
			width: auto;

			button {
				padding: 0.1rem;
			}

			span {
				color: #fff;
				font-size: 0.8rem;
				font-weight: 600;
			}
		}

		svg {
			font-size: 14px;
			color: #08f996;
			margin-right: 0.2rem;
			margin-top: 0.1rem;
		}

		p {
			margin: 0;
			padding: 0;
		}

		.like_section {
			display: flex;
			align-items: center;
			width: auto;
		}
	}

	.item_content {
		display: flex;
		justify-content: flex-start;
		font-family: 'Titillium Web', sans-serif;
		padding: 0.1rem 0.5rem;
		background: none;

		@include media($phone_all...) {
			padding: 0;
		}

		.goscore {
			width: 32px;
			height: 32px;
			margin-right: 10px;
			margin-top: 4px;
			margin-left: 6px;

			@include media($phone_all...) {
				margin-top: 2px;
				margin-right: 5px;
				margin-left: 0;
			}

			div {
				max-width: 32px;
				max-height: 32px;
			}
		}

		h3 {
			margin: 0;
			margin-top: 0.3rem;
			font-weight: 600;
			line-height: 0.95rem;
			font-size: 0.8rem;
			max-width: 100%;
			height: 40px;

			@include media($phone_all...) {
				width: 100%;
			}

			a {
				color: $whiteColor;
			}

			span {
				font-size: 0.6rem;
				font-weight: 400;
				clear: both;
				display: block;

				svg {
					position: relative;
					top: 0;
					left: 0.1rem;
					font-size: 0.6rem;
					color: #1aeff3;
				}
			}
		}

		.badges {
			display: flex;
			align-items: flex-start;
			gap: 4px;
			margin-left: auto;
			flex-wrap: wrap;
			max-width: 64px;

			img {
				cursor: pointer;
				margin-top: 5px;
				max-width: 30px;
			}
		}

		.description {
			display: block;

			a {
				color: #cac7bf;
				margin-top: 0.25rem;
				font-weight: 600;
				margin-bottom: 0;
				font-size: 0.7rem;
				line-height: 1rem;
				display: block;
				width: 100%;
				letter-spacing: 0.1rem;
			}

			p {
				font-size: 0.8rem;
				line-height: 18px;
				margin-top: 0;
				color: #cac7bf;
				font-family: 'Titillium Web', sans-serif;
				margin-bottom: 0;
				height: 40px;
				overflow: hidden;
			}
		}
	}

	&:hover {
		.social_link {
			background-color: #404040;
		}
	}
}

.carousel_left,
.carousel_right {
	position: relative;
	transform: scale(0.9);
	z-index: 0;
	border-radius: 6px;
	opacity: 0.5;
}

.carousel_left {
	margin-right: -64px;
}

.carousel_right {
	margin-left: -64px;
}

.trending {
	@include media($phone_all...) {}

	img.artwork {
		height: 30rem;
	}
}

.adoption {
	background: #fc8e0b !important;
}

.development {
	background: #bd1f40 !important;
}

.originality {
	background: #08f996 !important;
	color: #000 !important;
}

.distribution {
	background: #953b96 !important;
}