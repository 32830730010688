@import '~include-media/dist/include-media';
@import 'Variable.scss';

.list_item {
	width: 280px;
	height: auto;
	overflow: hidden;
	border-radius: 5px;
	display: inline-block;
	position: relative;
	margin: 15px 10px 15px;
	padding-bottom: 2rem;
	background: #152d53;
	z-index: 10;
	border-radius: 20px;

	img.artwork {
		width: 100%;
		object-fit: cover;
		float: left;
	}

	&.otherProduct {
		max-height: 375px;

		img.artwork {
			height: 180px;
		}

		.other_product_content {
			display: inline-block;
			min-height: initial;
			height: 17rem;
			width: 100%;
			margin-top: 0.5rem;

			.projectInfo {
				text-align: left;
				padding: 0 0.5rem;

				a.sub_content {
					color: $whiteColor;
					font-size: 1rem;
					max-width: 100%;
					height: auto;
					margin: 0.25rem 0.5rem 0;
					font-weight: 600;
					display: block;
				}

				.description {
					font-size: 0.9rem;
					line-height: 1rem;
					margin-top: 0;
					margin-bottom: 0.5rem;
					color: #fff;
					padding: 0 0.5rem;
					text-align: left;
				}
			}

			.view_link {
				position: absolute;
				z-index: 3;
				bottom: 0.25rem;
				background-color: #4e4bde;
				color: $whiteColor;
				border-radius: 20px;
				padding: 0.25rem 2.25rem;
				margin: 0.5rem 0.5rem;

				&:hover {
					background-color: #005aff;
				}
			}

			.view_link.Mui-disabled {
				background: #1d1d1d;
				cursor: not-allowed;
				color: #5b5b5b;
			}
		}
	}

	&:hover {
		.item_content {
			background-color: #231f20;
		}

		.social_link {
			background-color: #404040;
		}
	}
}

.project {
	background: #0c0b2f url('../../../assets/images/union.svg') no-repeat;

	img.artwork {
		margin: 1rem 0 0rem 1rem;
		max-height: 164px;
		max-width: 248px;
		border-radius: 14px;
		object-position: left top;
	}

	.projectName {
		margin: 0 0.5rem 0.25rem;
		font-size: 0.9rem;
	}
}

.link {
	.projectName {
		margin: -0.25rem 0.5rem 0.25rem;
		font-size: 0.9rem;
	}
}
