@import 'Variable';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.signups_table {
	margin-bottom: 50px;

	h4 {
		font-weight: 600;
		font-size: 16px;
	}

	table {
		width: 100%;
		font-size: 1.1rem;
		text-align: left;
		background: transparent;
		padding: 0.5rem;
		border-collapse: collapse;

		.center {
			text-align: center;
		}

		th {
			font-family: 'var(--font-manrope)';
			font-style: normal;
			font-weight: 400;
			font-size: 10px;
			line-height: 17px;
			letter-spacing: -0.018em;
			text-transform: uppercase;
			background: transparent;
			color: #ffffff;
			opacity: 0.6;
			border: none;
		}

		td {
			font-family: 'var(--font-manrope)';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: -0.018em;
			color: #ffffff;
			border: none;
			padding-left: 0;

			a {
				color: #ffffff;
			}

			&.join_date {
				font-size: 12px;
				opacity: 0.6;
			}

			.follow {
				svg {
					color: #59b7d8;
					font-size: 18px;
				}
			}
		}
	}

	.pagination {
		display: flex;
		justify-content: center;

		* {
			color: #ffffff;
		}

		button {
			&:disabled {
				* {
					color: #ffffff52;
				}
			}
		}
	}
}
