@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dashboard_product_section {
	width: calc(100% - 15rem);
	margin-left: 15rem;
	margin-top: 2rem;

	&.join_page {
		margin-top: 146px;
		margin-left: 8rem;

		@include media($phone_all...) {
			margin-left: 0;
		}

		.product_container {
			> div:first-child {
				pointer-events: none !important;

				div {
					@include media($phone_all...) {
						margin-top: 5px;
						margin-left: 5px;
					}
				}
			}
		}
	}

	@include media($phone_all...) {
		width: 100%;
		margin-left: 0;
		margin-top: 0.5rem;
	}

	.itemContainer {
		@include media($phone_all...) {
			width: 45%;
			margin: 0 2.5% 25px;
		}
	}

	.daoproposal,
	.updates {
		@include media($phone_all...) {
			width: 100%;
			text-align: center;
		}
	}

	.product_container {
		max-width: 76rem;
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		@media all and (max-width: 1536px) {
			max-width: 57rem;
		}

		@media all and (max-width: 1220px) {
			max-width: 38rem;
		}

		@media all and (max-width: 855px) {
			max-width: 28rem;
		}

		@media all and (max-width: 775px) {
			max-width: 25rem;
		}

		@media all and (max-width: 1220px) {
			max-width: 38rem;
		}

		@media all and (max-width: 855px) {
			max-width: 28rem;
		}

		@media all and (max-width: 775px) {
			max-width: 25rem;
		}
		
		.filtered_data_chip {
			position: relative;
			
			.open_button {
				position: absolute;
				z-index: 100;
				top: 15px;
				left: 10px;
				
				svg {
					font-size: 26px;
				}
				
				display: none;
				
				@include media($phone_all...) {
					display: block;
				}
			}
		}

		.filtered_data_chip {
			position: relative;

			.open_button {
				position: absolute;
				z-index: 100;
				top: 15px;
				left: 10px;

				svg {
					font-size: 26px;
				}

				display: none;

				@include media($phone_all...) {
					display: block;
				}
			}
		}

		.loader {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 90px;
			width: 100%;
			background: rgba(255, 255, 255, 0.25);
			margin: 10px 10px 20px 10px;
			border-radius: 12px;

			@include media($phone_all...) {
				width: 100%;
				padding: 2rem;
				font-size: 1.1rem;
				text-align: center;
			}
		}
	}

	.end {
		background: #333;
		width: 100%;
		padding: 20px;
		text-align: center;
		border-radius: 8px;
		margin-bottom: 10px;
	}

	.header {
		position: relative;
		width: 97%;
		margin-top: 3.5rem;

		@include media($phone_all...) {
			display: none;
		}

		.background {
			width: 100%;
		}

		.filmio_text {
			position: absolute;
			top: 28%;
			left: 15%;
			width: 30%;
		}

		.header_text {
			position: absolute;
			top: 15%;
			right: 4%;
			width: 36%;
		}

		.banner {
			position: absolute;
			left: 8px;
			bottom: -12px;
			width: 100%;
			background: black;
		}
	}

	.top_join {
		display: flex;
		position: fixed;
		width: 100%;
		align-items: center;
		justify-content: center;
		z-index: 100;
		left: 0;

		span {
			border-radius: 0px 0px 10px 10px;
			background: #152d53;
			position: absolute;
			top: -80px;
			font-size: 17px;
			font-style: normal;
			padding: 8px 100px;

			@include media($phone_all...) {
				text-align: center;
				padding: 8px 40px;
				border-radius: 0px;
			}

			a {
				cursor: pointer;
			}

			strong {
				font-weight: 700;
			}
		}
	}
}
