$primaryColor: #ffffff;
$secondaryColor: #262626;

$navyBlueColor: #005aff;
$cyanColor: #00baef;
$bodyBg: #121212;
$blueColor: #5ad2f2;
$darkGrayish: #21202e;
$whiteColor: #ffffff;
$voiletColor: #d006ab;
$linkColor: #1160fa;

$linkColorOther: #53abc9;
$blackColor: #000000;
