.container {
	.score_container {
		display: flex;
		align-items: center;
		position: relative;

		svg {
			font-size: 14px;
		}

		.score {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			background-color: transparent;
			height: 104px;
			width: 104px;
			border-radius: 50%;

			&:hover {
				cursor: pointer;
			}

			.ring_image {
				position: absolute;
				width: 100%;
				border-radius: 50%;
			}

			.center_image {
				position: absolute;
				width: 50%;
				z-index: 2;
			}

			.center_text {
				z-index: 2;
				font-weight: 600;
				font-style: normal;
				font-weight: 600;
				font-size: 10px;
				line-height: 24px;
				color: #fff;
			}
		}

		.text {
			font-size: 0.9rem;
			line-height: 1.15rem;
			width: 11.5rem;
			text-align: left;
			margin-left: 20px;
			color: #8c8c8c;

			&:hover {
				color: #fff;
				cursor: pointer;
			}
		}

		.bar {
			position: absolute;
			z-index: 1;
		}
	}

	.bottom_text {
		margin: 10px 12px 24px;
		font-size: 1rem;

		sup {
			font-size: 0.6rem;
		}
	}
}

.lockedBar {
	box-shadow: none !important;

	&::after {
		box-shadow: none !important;
	}
}