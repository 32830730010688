@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.blockElement {
	float: left;
	display: block;
	width: 100%;
}

.details_tab {
	> div {
		border: none;
	}

	.tabs_list {
		padding: 1rem 0 0;

		> div > div {
			justify-content: flex-start;
			align-items: baseline;

			button {
				margin-right: 50px;

				@include media($phone_all...) {
					margin-right: 0;
					padding: 4px 7px;
					font-size: 13px;
				}
			}
		}

		.switch_fan_creator {
			background-color: #152d53;
			color: #fff;
			padding: 6px 16px;
			margin-left: auto;
			margin-right: 0;
			border-radius: 25px;
			border: none !important;
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 24px;

			@include media($phone_all...) {
				padding: 2px 8px;
				font-size: 12px;
			}

			&:hover {
				border: none;
			}
		}

		button {
			color: #bcb2aa;
			font-size: 1rem;
			font-weight: 600;
			text-transform: capitalize;
			max-width: fit-content;
			align-items: flex-start;
			min-width: 20px;
			padding: 0.25rem 0.75rem;
			border-bottom: 0.2rem solid transparent;

			&.Mui-selected,
			&:hover {
				color: $whiteColor;
				border-color: #fff;
			}
		}

		.tab_custom_indicator {
			background-color: #fff;
			height: 0.2rem;
		}
	}
}
