@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.voter_item {
	display: flex;
	flex-direction: row;
	flex-basis: 100%;
	align-items: center;
	justify-content: flex-start;
	margin-top: 12px;

	& > div {
		display: flex;
		text-align: left;
	}

	.voteName,
	.name {
		min-width: 140px;
		letter-spacing: 0;

		p {
			letter-spacing: 0;
		}
	}

	.votingWeight {
		min-width: 100px;
		letter-spacing: 0;
	}

	.voteHash {
		min-width: 70px;
		letter-spacing: 0;
	}

	.user_avatar {
		width: 29px;
		height: 29px;
		flex-shrink: 0;
		border-radius: 50%;
		border: 1px solid #fff;
		margin-right: 10px;
	}

	.voteName {
		& > div {
			& > div {
				@extend .user_avatar;
				font-size: 14px;
			}
		}
	}

	p {
		color: #fff;
		font-variant-numeric: lining-nums tabular-nums;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}

	.green,
	.red {
		width: 11px;
		height: 11px;
		border-radius: 50%;
		margin-right: 6px;
		display: inline-flex;
		align-content: center;
	}

	.red {
		background-color: red;
	}

	.green {
		background-color: #3cbb5b;
	}

	.retry {
		background: #e9010130 !important;
		height: 26px;

		&:disabled {
			color: #ffffff6b;
			font-weight: 400;
		}
	}

	.failed {
		border-radius: 5px;
		padding: 0 15px;
		background: #e9010130;
		font-size: 11px;
	}
}
