@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dialog {
	z-index: 997 !important;

	.dialog_paper {
		width: 602px;
		border-radius: 30px;
		// border: 1px solid #2e2bba;
		background: linear-gradient(
			180deg,
			rgba(45, 42, 180, 0.5) 0%,
			rgba(20, 18, 78, 0.5) 100%
		);
		backdrop-filter: blur(3px);

		@include media($phone_all...) {
			margin: 20px;
		}

		.dialog_content {
			padding: 32px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			justify-content: center;
			align-items: center;

			p {
				text-align: left;
				margin-bottom: 10px;
			}

			h2 {
				font-family: Titillium Web;
				font-size: 20px;
				font-weight: 700;
				line-height: 30.42px;
				color: #ffffff;
			}

			ul {
				counter-reset: item;
				text-align: left;

				&.main_list {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					max-height: 290px;
					overflow: overlay;

					&::-webkit-scrollbar {
						background-color: transparent;
						width: 7px;
					}

					&::-webkit-scrollbar-thumb {
						background: transparent;
						background-color: #4e4bde;
						border-radius: 50px;
					}

					&::-webkit-scrollbar-track {
						background: transparent;
						border-radius: 50px;
					}
				}
			}

			.main_list li {
				display: block;
				text-align: left;
			}

			li {
				font-family: Titillium Web;
				color: #ffffff;
				line-height: 24.34px;
				font-size: 16px;
				margin-bottom: 8px;
			}

			li.level1 {
				font-weight: 700;
			}

			li.level1:before {
				content: counters(item, '.') '. ';
			}

			li.level2 {
				font-weight: 400 !important;
				margin-left: 12px;
			}

			li:before {
				content: counters(item, '.') ' ';
				counter-increment: item;
			}

			.flex_box {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			button.down {
				margin-top: -15px;
				opacity: 50%;
			}

			.MuiCheckbox-root {
				color: #fff !important;
			}

			h2.active_head {
				font-family: Titillium Web !important;
				font-size: 16px !important;
				font-weight: 400 !important;
				line-height: 18.34px !important;
				text-align: left !important;
				display: inline-block;
				border-bottom: 1px solid #fff;
				color: #fff;
			}

			.agree_section {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				color: #fff;
				gap: 8px;

				.check_active.MuiCheckbox-root.Mui-checked {
					background-color: #2e2c6a !important;
				}
			}

			.bottom {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;
				gap: 24px;
				padding-left: 24px;

				@include media($phone_all...) {
					padding-left: 0;
					align-items: center;
				}
			}

			.action_btns {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 16px;

				.agree {
					width: 237.71px;
					height: 40px;
					border-radius: 25px;
					font-family: Titillium Web;
					font-size: 14px;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
					background: #4e4bde;

					&:disabled {
						opacity: 20%;
						color: #fff !important;
					}

					@include media($phone_all...) {
						width: 127px;
					}
				}

				.cancel {
					width: 237.71px;
					height: 40px;
					border-radius: 25px;
					font-family: Titillium Web;
					font-size: 14px;
					font-weight: 600;
					line-height: 24px;
					text-align: center;
					background: #2e2c6a;

					&:disabled {
						color: #fff !important;
						background: rgba(46, 44, 106, 0.8);
					}

					@include media($phone_all...) {
						width: 127px;
					}
				}
			}

			.disabled_header {
				font-family: Titillium Web;
				font-size: 16px;
				font-weight: 400;
				line-height: 24.34px;
				text-align: left;
				opacity: 0.2;
				display: flex;
				align-items: center;
				gap: 5px;

				&::before {
					content: '';
					display: block;
					width: 14px;
					height: 14px;
					border-radius: 3px;
					border: 1.3px solid #ffffff;
				}
			}

			@include media($phone_all...) {
				padding: 20px;
			}
		}
	}

	&.warning {
		.dialog_paper {
			width: auto;
			overflow: visible;
		}

		.dialog_content {
			overflow: auto;
		}
	}
}
