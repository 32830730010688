@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.project_detail {
	margin-top: 2rem;
	max-width: 1080px !important;
	margin-bottom: 5rem;
	position: relative;
	z-index: 2;

	@include media($phone_all...) {
		margin-top: 5rem;
		padding: 0 10px;
	}

	.cover_container {
		width: 100%;
		overflow: hidden;
		position: relative;
		display: flex;
		border-radius: 15px;
		min-height: 450px;

		&.no_dashboard {
			max-height: 255px;
		}
		
		@include media($phone_all...) {
			min-height: auto;
		}

		@include media($phone_all...) {
			min-height: auto;
		}

		img {
			width: 100%;
			height: 100%;

			@include media($phone_all...) {
				height: 100%;
			}
		}
	}

	.banner {
		background: rgba(21, 45, 83, 0.5);
		border-radius: 10px;
		padding: 1.1rem 1.5rem;
		margin-bottom: 1.5rem;

		h3 {
			font-size: 1.2rem;
			margin: 0;
			font-weight: 400;
			margin-bottom: 0.5rem;

			svg {
				font-size: 1rem;
				color: #8c5bea;
			}
		}

		button {
			border-radius: 10px;
			margin-top: 1rem;
			background: #5553da;
			border: 1px solid #5553da;
			font-size: 0.8rem;

			svg {
				font-size: 0.8rem;
			}

			&:hover {
				background: #2e2c6a;
				border: 1px solid #5553da;
			}
		}

		.bannerMessage {
			margin: 0;
			padding: 0;

			p {
				font-size: 0.9rem !important;
				line-height: 1.1rem;
			}
		}
	}

	.top_detail_list {
		margin-bottom: 1%;

		@include media($phone_all...) {
			margin-left: -0.5rem;
		}

		h2 {
			font-size: 1.7rem;
			font-weight: 600;
			margin: 0 0;

			@include media($phone_all...) {
				font-size: 25px;
				margin-left: 0.5rem;
			}

			span {
				margin-left: 15px;

				@include media($phone_all...) {
					margin-left: 4px;
				}

				a {
					color: $linkColorOther;
					font-size: 0.9rem;
					font-weight: 300;
				}
			}

			span.contestBadge {
				display: inline-flex;
				margin-top: -3px;
				margin-left: 10px;
				line-height: 25px;
				vertical-align: middle;
			}
		}

		.title_metrics {
			@include media($phone_all...) {
				max-width: 100%;
			}
		}

		ul {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.owner {
				h3 {
					margin: 0;
					line-height: 1rem;
				}

				a {
					color: #fff;
					font-size: 1rem;

					@include media($phone_all...) {
						font-size: 14px;
					}

					> span {
						font-size: 0.7rem;
						font-weight: 100;
					}
				}

				svg {
					cursor: pointer;
					font-size: 14px;
					color: #1aeff3;
					float: right;
					margin-left: 0.25rem;
				}
			}

			li {
				display: flex;
				font-size: 1rem;
				align-items: center;
				margin-right: 5px;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.social_info {
			li {
				width: auto;

				svg {
					color: #08f996;
					font-size: 16px;

					@include media($phone_all...) {
						font-size: 14px;
					}
				}
			}
		}
	}

	.avatar_container {
		display: flex;
		justify-content: flex-end;

		@include media($phone_all...) {
			justify-content: flex-start;
			max-width: 100%;
			flex-basis: 100%;
			flex-grow: 1;
			margin-left: 0.5rem;
		}

		ul {
			@include media($phone_all...) {
				width: 100%;
				margin: 0.5rem 0;
			}
		}
	}

	.social_link {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		padding: 0.6rem 0 15px 0.5rem;

		li {
			margin: 0 12px 0 0;
			position: relative;
			list-style: none;
			display: inline-block;
			margin: 0.25rem 0.5rem;
			background: #000;
			padding: 0;
			border: 0.1rem solid #0b72a7;
			border-radius: 5px;
			width: 8rem;
			height: 4rem;

			&:last-child {
				margin-right: 0;
			}

			a,
			button {
				display: block;
				text-align: center;
				font-weight: 300;
				padding: 0.5rem 0;
				color: #fff;
				width: 100%;
				font-size: 1.2rem;
				line-height: 1rem;
				background: transparent;
				cursor: pointer;

				&:hover {
					background: #0b72a7;
				}

				&:disabled {
					opacity: 0.5;
				}
			}

			a {
				&[aria-disabled='true'] {
					opacity: 0.5;
				}
			}

			button {
				border: none;
			}

			div {
				position: absolute;
				bottom: 0;
				width: 100%;
				color: #fff;
				background: #2d3f41;
				font-size: 0.9rem;
				font-weight: 100;
				text-align: right;
				padding: 2px;
				border-radius: 0 0 3px 3px;

				img {
					display: inline;
					position: relative;
					top: 0.15rem;
					width: 1rem;
					margin-left: 0.15rem;
				}
			}
		}
	}
}
