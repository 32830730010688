@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.text_icon {
	span {
		@include media($phone_all...) {
			display: block;
		}
	}
}

.vaultlock {
	display: flex;
	background: #222 !important;

	.content {
		display: flex;
		padding: 1rem;
		color: #fff;

		.left {
			h3 {
				line-height: 2rem;
				font-weight: 700;
				text-transform: capitalize;
				margin-bottom: 0.5rem;
			}

			.images_container {
				position: relative;

				.qr {
					width: 100px;
					height: 100px;
				}

				.logo {
					width: 20px;
					position: absolute;
					top: 40px;
					left: 40px;
				}
			}
		}

		.right {
			margin-left: 1rem;

			h3 {
				max-width: 100px;
				font-size: 14px;
				letter-spacing: 0;
				margin-top: 1rem;

				span {
					color: #53abc9;
				}
			}
		}
	}
}
