@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.hide {
	display: none;
}

.create_review {
	padding: 1.25rem 2rem;
	margin: 1rem 0 2rem;
	background-color: #192531 !important;
	border-radius: 0.5rem !important;
	position: relative;
	
	@include media($phone_all...) {
		margin: 0 0 2rem;
	}

	&.hide {
		display: none;
	}

	h5 {
		margin-top: 2rem;

		&:first-child {
			margin-top: 0;
		}
	}

	input {
		background-color: #193044 !important;
		border-color: #1D639B !important;
	}

	textarea {
		background-color: #193044;
		min-height: 8rem;
		width: 100%;
		color: #ffffff;
		border: 1px solid #1D639B;
		padding: 0.5rem;
		border-radius: 0.5rem;
		font-size: 16px;
	}

	.chooseRating {
		margin: 15px 0 10px 0;

		svg {
			font-size: 25px;
		}
	}

	.recaptcha {
		margin-top: 20px;

		> div:first-child {
			float: left;
			opacity: 0.5;
			margin-bottom: 24px;

			&:hover {
				opacity: 1;
			}
		}
	}

	.text_area_remaining_characters_feedback {
		color: #fff;
		font-size: 16px;
		text-align: left;
	}

	.close_right_top {
		color: $whiteColor;
		position: absolute;
		right: 20px;
		top: 20px;
		cursor: pointer;
		opacity: 0.7;
	}

	p {
		color: $whiteColor;
		margin-left: 0;
	}

	.cancelButton {
		margin-left: 1rem;
		padding: 0 1.5rem;
		height: 38px;
		border-radius: 6px;
		border: 1px solid rgba(255, 255, 255, 0.5);
	}

	.actions {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: flex-start;
		margin-top: 0;
		gap: 0.5rem;
	}
}
