@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.contest_table {
	width: 100%;
	border-radius: 11px;
	background: #21202f;
	padding: 24px 40px;

	.table_container {
		td,
		th {
			color: #fff;
			border: none;
			opacity: 0.75;
		}

		th {
			text-transform: uppercase;
		}

		td {
			text-transform: capitalize;

			&.highlight {
				opacity: 1;
				font-weight: 700;
			}

			&.disabled {
				opacity: 0.25;
			}

			span {
				color: #01b6df;
				cursor: pointer;
			}
		}

		img {
			height: 40px;
		}
	}
}
