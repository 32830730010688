.banner {
	display: inline-block;
	background: rgba(21, 45, 83, 0.5);
	border-radius: 10px;
	padding: 1.1rem 1.5rem;
	margin-bottom: 1rem !important;

	h3 {
		font-size: 1.2rem;
		margin: 0;
		font-weight: 400;
		margin-bottom: 0.5rem;

		svg {
			font-size: 1rem;
			color: #8c5bea;
		}
	}

	button {
		border-radius: 10px;
		margin-top: 1rem;
		background: #5553da;
		border: 1px solid #5553da;
		font-size: 0.8rem;

		svg {
			font-size: 0.8rem;
		}

		&:hover {
			background: #2e2c6a;
			border: 1px solid #5553da;
		}
	}

	.bannerMessage {
		margin: 0;
		padding: 0;

		p {
			font-size: 0.9rem !important;
			line-height: 1.1rem;
		}
	}
}
