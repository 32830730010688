@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.subtitle {
	font-weight: 700;
	font-size: 16px;
	margin-top: 32px;
	margin-bottom: 12px;
	color: #ffffff;
}

.reviewed {
	padding: 1.75rem 2rem;
	margin: 3rem 0 2rem;
	background-color: #1e1d47 !important;
	border-radius: 20px !important;
	position: relative;
	max-width: 600px;
	font-weight: 600;
	font-size: 1.2rem;
	text-align: center;
}

.badges_achievements {
	background: rgba(0, 0, 0, 0.35);
	border-radius: 20px;
	padding: 30px;
	margin-top: 1rem !important;

	@include media($phone_all...) {
		max-width: 100%;
		padding: 10px;
	}

	h3 {
		@extend .subtitle;

		&:first-child {
			margin-top: 5px;
		}
	}
}

.badges_achievements :last-child {
	background: none;
	padding: 0;
}

.reviewRatting {
	margin-top: 0 !important;

	> div {
		width: 100%;
		
		@include media($phone_all...) {
			padding-top: 0 !important;
		}
	}

	.border-top {
		margin-top: 30px;
	}

	.projectinfo {
		margin-top: 0;
	}

	.stakes {
		margin-top: 0;
		margin-bottom: 2rem;
	}

	.explanations {
		display: flex;
		margin-top: 45px;
		margin-bottom: 45px;
		margin-left: 28px;
	}

	.recent_activity {
		padding: 28px;
	}

	.add-review-btn {
		margin-top: 4px;
		margin-left: 0;
		padding: 6px 18px;
	}
}

.message_box {
	display: flex;
	align-content: center;
	width: 600px;
	background: #1e1d47;
	box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding: 1rem;
	margin-top: 2rem;
	cursor: pointer;

	@include media($phone_all...) {
		width: 100%;
	}

	img {
		float: left;
		margin-left: 1rem;
		margin-right: 1em;

		@include media($phone_all...) {
			width: 70px;
			margin-left: 0.5rem;
			margin-right: 0.5em;
		}
	}

	h1 {
		font-size: 20px;
		line-height: 1.1rem;
		display: flex;
		align-items: center;
		font-weight: 600;

		@include media($phone_all...) {
			margin-top: 0;
			font-size: 16px;
		}
	}
}
