@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.contest_badge {
	background: linear-gradient(90deg, #f768ca 0%, #4e4bde 50%, #01b6df 100%);
	width: calc(100% - 100px);
	aspect-ratio: 812 / 65;
	border-radius: 8px;

	@include media($phone_all...) {
		width: calc(100% - 20px);
		aspect-ratio: 0;
		border-radius: 8px;
	}

	.stack {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;

		@include media($phone_all...) {
			flex-direction: column;
			padding: 5px 10px;

			p {
				text-align: center;
			}
		}

		button {
			font-weight: 600;
			font-size: 16px;
			padding: 4px;
			margin-left: 4px;
			text-decoration: underline;
			@include media($phone_all...) {
				font-size: 14px;
				min-width: 130px;
			}

			&:hover {
				background: none;
			}

			img {
				margin-left: 5px;
				width: 26px !important;

				@include media($phone_all...) {
					width: 20px !important;
				}
			}
		}
	}
}
