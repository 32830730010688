@import '~include-media/dist/include-media';
@import 'Variable.scss';
@import 'MediaQuery.scss';

.proposal_item {
	position: relative;
	z-index: 100;
	width: 280px;
	padding: 12px;
	overflow: hidden;
	display: inline-block;
	background: #152d53;
	border-radius: 20px;
	margin: 15px 10px 15px;
	border: 1px solid #2e2bba;

	@include media($phone_all...) {
		margin: 0 auto;
	}

	.not_visited {
		position: absolute;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: red;
		right: 10px;
		z-index: 1;
		top: 10px;
	}

	.header {
		position: relative;
		max-height: 120px;
		overflow: hidden;
		border-radius: 14px 14px 0 0;

		@include media($phone_all...) {
		}

		img {
			width: 100%;
		}
	}

	.info {
		position: relative;
		z-index: 2;
		background: #161622;
		display: flex;
		flex-direction: column;
		padding: 10px 10px 3px 10px;
		margin-top: -4px;

		.top {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media($phone_all...) {
			}

			.status_pill {
				font-size: 11px;
				background-color: #3cbb5b;
				border-radius: 50px;
				padding: 0px 20px;
				font-weight: 600;
				text-align: center;

				&.ended {
					color: #000;
					background: linear-gradient(180deg, #ffd873 0%, #f2c44d 100%);
				}
			}
		}

		.votingWeight_container {
			display: flex;
			align-items: center;
			margin-top: 5px;

			button {
				padding-left: 5px;

				svg {
					color: #08f996;
				}
			}

			.bar {
				height: 4px;
				border-radius: 5px;
				flex-grow: 1;
				margin: 10px;
			}

			.text_weight {
				color: #fff;
				font-size: 9px;
				font-style: normal;
				font-weight: 600;
				line-height: 13px;
				letter-spacing: 0px;
			}

			.text_outcome {
				color: #fff;
				font-size: 9px;
				font-style: normal;
				font-weight: 600;
				line-height: 13px;
				margin-right: 5px;
				letter-spacing: 0px;
			}

			svg {
				font-size: 9px;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		text-align: left;

		.owner {
			font-size: 10px;
			letter-spacing: 0px;
		}

		.title {
			font-weight: 600;
			background: linear-gradient(45deg, #6b3bfa, #f11e76);
			background: -webkit-linear-gradient(45deg, #6b3bfa, #f11e76);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			letter-spacing: 0px;
			line-height: 17px;

			@include media($phone_all...) {
				margin-bottom: 10px;
			}
		}

		.desc {
			margin: 5px auto 20px auto;
			font-size: 12px;
			line-height: 15px;
			letter-spacing: 0px;

			@include media($phone_all...) {
			}

			p {
				margin: 0;
				line-height: 15px;

				strong {
					line-height: 35px !important;
				}
			}
		}

		button {
			margin: 0 auto 5px auto;
			border-radius: 20px;
			padding: 5px 40px;
			background: #4e4bde;
			letter-spacing: 0px;
		}
	}
}
