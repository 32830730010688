@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.unlock_progress {
	padding-right: 20px;

	.text {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: normal;
	}

	.title {
		@extend .text;
		color: #ffffff;
		margin-bottom: 4px;
	}

	.faint {
		@extend .text;
		color: #8c8c8c;
	}

	.progress_content {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		@include media($phone_all...) {
			justify-content: flex-start;
		}

		.progress_bar {
			height: 5px;
			border-radius: 5px;
			margin-bottom: 4px;
		}

		.progress_item {
			width: 82px;
			margin: 10px 0;
			margin-right: 10px;

			p {
				text-transform: uppercase;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 15px;
			}
		}
	}
}
