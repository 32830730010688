@import 'ThemeScss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.team_tab_section {

	@include media($phone_all...) {
		padding-bottom: 20px;
	}

	.team_header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 2rem 0 2%;
		background-color: #152d53;
		border-radius: 10px;

		@include media($phone_all...) {
			margin-bottom: 1.5rem;
		}

		.addButton {
			background: #3cbb5b;
			border-radius: 25px;
			min-width: 82px;
			padding: 0.25rem;
			margin-right: 10px;
		}

		.tabs_list {
			border: none;
			padding: 0;

			@include media($phone_all...) {
				min-height: auto;
			}

			button {
				margin-right: 16px;
				border-width: thin;
				color: #fff;
				text-transform: none;

				@include media($phone_all...) {
					font-size: 14px;
					padding: 8px;
					min-width: auto;
					min-height: auto;
				}

				&:hover {
					border-color: #fff;
				}
			}
		}
	}
}